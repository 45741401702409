<template>
  <div>
    <VTitle :title="$t('app.details')" class="section-title" />

    <div class="grid responsive-cols gap-5 mt-10">
      <div>
        <UserDetailsForm
          :data="user"
          :is-loading="isLoading"
          @click:save="$emit('click:save', $event)"
          @click:cancel="$emit('click:cancel', $event)"
        >
          <template #title>
            {{ $t("app.details") }}
          </template>

          <template #actions="{onSave, onCancel}">
            <div
              class="flex"
              :class="isAnonymizeEnabled ? 'justify-between' : 'justify-end'"
            >
              <VButton
                v-if="isAnonymizeEnabled && isSysAdmin"
                data-test="anonymize"
                class="btn-outline-danger"
                :label="$t('app.anonymize')"
                @click="showModal"
              />

              <div>
                <VButton
                  data-test="cancel"
                  class="btn-outline-secondary mr-1"
                  :label="$t('app.cancel')"
                  @click="onCancel"
                />

                <VButton
                  data-test="save"
                  class="btn-primary"
                  :label="$t('app.save')"
                  :is-loading="isLoading"
                  @click="onSave"
                />
              </div>
            </div>
          </template>
        </UserDetailsForm>

        <Logs :data="user" class="mt-8" @click="$emit('click:logs')" />
      </div>

      <div>
        <UserPasswordForm
          :is-saving="isSaving"
          :user="user"
          class="mb-8"
          @click:save="onClickSavePassword"
        />

        <UserAPIAccessForm
          v-if="user.id && user.api_key"
          :id="user.id"
          :api-key="user.api_key"
          class="mb-8"
          @click:regenerate="$emit('click:regenerate')"
        />

        <UserPIN
          v-if="isSysAdmin && user?.id && user?.pin_code"
          :id="user?.id"
          :pin="user?.pin_code"
          class="mb-8"
          @refresh="$emit('refresh')"
        />

        <UserLicenseForm
          v-if="user.id && user.license"
          :user-id="user.id"
          :user-license="user.license"
          @update:license="$emit('update:license', $event)"
        />
      </div>
    </div>

    <WarningModal
      id="anonymize-modal"
      :warning="$t('app.anonymize_msg', { name: name })"
    >
      <template #icon>
        <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
      </template>

      <template #actions>
        <div class="px-5 pb-8 text-center">
          <VButton
            class="btn-outline-secondary w-24 mr-1"
            :label="$t('app.cancel')"
            @click="hideModal"
          />

          <VButton
            class="btn-danger"
            :label="$t('app.anonymize')"
            @click="onClickAnonymize"
          />
        </div>
      </template>
    </WarningModal>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useModal from "@/composables/useModal";
import useRequest from "@/composables/useRequest";
// Components
import UserDetailsForm from "@/views/administration/users/Form";
import UserPasswordForm from "@/views/administration/users/UserPassword";
import UserAPIAccessForm from "@/views/administration/users/UserAPIAccess";
import UserPIN from "@/views/administration/users/UserPIN";
import UserLicenseForm from "@/views/administration/users/UserLicense";
import Logs from "@/components/Logs";
import VTitle from "@/components/VTitle";
import VButton from "@/components/VButton";
import WarningModal from "@/components/modals/WarningModal";

export default {
  components: {
    VTitle,
    UserDetailsForm,
    UserPasswordForm,
    UserAPIAccessForm,
    UserLicenseForm,
    UserPIN,
    Logs,
    VButton,
    WarningModal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "click:logs",
    "click:save",
    "click:cancel",
    "click:anonymize",
    "click:regenerate",
    "update:license"
  ],
  setup(props, context) {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const toast = inject("toast");

    // Composables
    const { request, isSuccess } = useRequest();
    const { showModal, hideModal } = useModal("#anonymize-modal");

    // DATA
    const isSaving = ref(false);

    // COMPUTED
    const name = computed(() => {
      const { firstname, lastname } = props?.user ?? {};

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    const documentTitle = computed(() => {
      const title = `${t("app.details")} - ${t("app.users", 2)} - ${t(
        "app.administration"
      )}`;

      if (name.value) {
        return `${name.value} - ${title}`;
      }

      return title;
    });
    // eslint-disable-next-line
    const isAnonymizeEnabled = computed(
      () => store.getters["app/anonymize_enabled"]
    );
    const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);

    // METHODS
    const onClickSavePassword = async data => {
      isSaving.value = true;
      const { id, firstname, lastname, username } = props?.user ?? {};

      const response = await request({
        endpoint: "administration.users.password",
        pathParams: { id },
        data,
        shotToaster: false
      });

      if (isSuccess(response)) {
        const name = `${firstname} ${lastname} (${username})`;
        const message = t("app.changed_user_password", { name });
        toast.show(message, "Success");
      }

      isSaving.value = false;
    };

    const onClickAnonymize = () => {
      hideModal();
      context.emit("click:anonymize");
    };

    return {
      name,
      isAnonymizeEnabled,
      isSaving,
      documentTitle,
      onClickSavePassword,
      onClickAnonymize,
      isSysAdmin,
      // useModal
      showModal,
      hideModal
    };
  }
};
</script>
