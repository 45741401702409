<template>
  <VCard>
    <template #title>
      <h2 class="font-medium text-base mr-auto">
        {{ $t("app.pin") }}
      </h2>
    </template>

    <template #content>
      <div class="flex">
        <input
          :value="pin"
          type="text"
          readonly
          class="form-control mr-2 pin-value"
        />

        <VButton
          class="btn-outline-primary mr-2"
          icon="fa-copy"
          @click="onClickCopy"
        />

        <VButton
          class="btn-primary"
          :is-loading="isRegenerating"
          :label="$t('app.regenerate')"
          @click="onClickRegeneratePIN"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VButton from "@/components/VButton";
import VCard from "@/components/VCard";

export default {
  components: {
    VButton,
    VCard
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    pin: {
      type: String,
      required: true
    }
  },
  emits: ["click:regenerate"],
  setup(props, context) {
    // MISC
    const { t } = useI18n();
    const toast = inject("toast");

    // Composables
    const { request } = useRequest();

    // Data
    const isRegenerating = ref(false);

    // METHODS
    const onClickCopy = () => {
      const el = document.querySelector(".pin-value");
      el.focus();
      el.select();

      try {
        const hasCopied = document.execCommand("copy");

        if (hasCopied) {
          toast.show(t("app.copied"), "Success");
        } else {
          toast.show(t("app.unable_copy"), "Failure");
        }
      } catch (err) {
        toast.show(t("app.unable_copy"), "Failure");
      }
    };

    const regeneratePIN = async () => {
      isRegenerating.value = true;

      await request({
        endpoint: "administration.users.reset-pin",
        pathParams: { id: props.id }
      });

      isRegenerating.value = false;
    };

    const onClickRegeneratePIN = async () => {
      await regeneratePIN();
      context.emit("refresh");
    };

    return {
      onClickCopy,
      isRegenerating,
      onClickRegeneratePIN
    };
  }
};
</script>
