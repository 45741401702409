<template>
  <FormCard
    :is-loading="isLoading"
    @click:cancel="onCancel"
    @click:save="onSave"
  >
    <template v-if="$slots.title" #title>
      <slot name="title" />
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <VText
          v-model="v.username.$model"
          :label="$t('app.username')"
          :readonly="readonly"
          data-test="username"
          :errors="v.username.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.external_code.$model"
          :label="$t('app.external_code')"
          :readonly="readonly"
          :errors="v.external_code.$errors"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_users_external_code") }}
          </template>
        </VText>

        <VText
          v-model="v.firstname.$model"
          :label="$t('app.firstname')"
          data-test="firstname"
          :readonly="readonly"
          :errors="v.firstname.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.lastname.$model"
          data-test="lastname"
          :label="$t('app.lastname')"
          :readonly="readonly"
          :errors="v.lastname.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.title.$model"
          :label="$t('app.position')"
          :readonly="readonly"
          :errors="v.title.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.designations.$model"
          :label="$t('app.designations')"
          :readonly="readonly"
          :errors="v.designations.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.phone.$model"
          :label="$t('app.phone')"
          :readonly="readonly"
          :errors="v.phone.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.mobile.$model"
          :label="$t('app.mobile')"
          :readonly="readonly"
          :errors="v.mobile.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.email.$model"
          data-test="email"
          :label="$t('app.email')"
          :readonly="readonly"
          :errors="v.email.$errors"
          @keyup.enter="onSave"
        />

        <VSelect
          v-if="isCreate"
          v-model="v.license_id.$model"
          data-test="license_id"
          :label="$t('app.license_id')"
          :options="options.license_id"
          :readonly="readonly"
          :errors="v.license_id.$errors"
          @keyup.enter="onSave"
        />

        <VSelect
          v-model="v.language.$model"
          data-test="language"
          :label="$t('app.languages', 1)"
          :options="options.language"
          :readonly="readonly"
          :errors="v.language.$errors"
          @keyup.enter="onSave"
        />

        <VSelect
          v-model="v.user_type.$model"
          data-test="user_type"
          :label="$t('app.user_type')"
          :options="options.user_type"
          :readonly="readonly"
          :errors="v.user_type.$errors"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_users_type") }}
          </template>
        </VSelect>

        <VWysiwyg
          v-model="v.details.$model"
          :label="$t('app.details')"
          class="col-span-2"
          :readonly="readonly"
          :errors="v.details.$errors"
        />

        <VSelect
          v-model="v.status.$model"
          :label="$t('app.status')"
          :options="options.status"
          :readonly="readonly"
          :errors="v.status.$errors"
          @keyup.enter="onSave"
        />
      </div>
    </template>

    <template #actions>
      <slot name="actions" :onSave="onSave" :onCancel="onCancel" />
    </template>
  </FormCard>
</template>

<script>
import { onMounted } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useUser from "@/composables/useUser";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";

export default {
  mixins: [FormMixin],
  setup(props, context) {
    // Composables
    const { value, rules, options, getOptions } = useUser();

    // Composables
    const { v, onSave, onCancel, isCreate } = useForm(props, context, {
      value,
      rules
    });

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    return {
      // useUser
      value,
      options,
      // useForm
      v,
      onSave,
      onCancel,
      isCreate
    };
  }
};
</script>
