<template>
  <VCard>
    <template #title>
      {{ $t("app.licenses", 1) }}
    </template>

    <template #content>
      <VSelect
        :model-value="license"
        :options="options"
        @update:modelValue="onUpdate"
      >
        <template #label="{ id }">
          <label :for="id" class="form-label">
            {{ $t("app.select_new_license") }}
          </label>
        </template>
      </VSelect>

      <div class="flex justify-between items-center mt-5">
        <div class="flex justify-between items-center">
          <LicenseLogo class="mr-4" />

          <div>
            <div class="font-medium text-base">
              {{ userLicense?.name }}
            </div>

            <div class="text-sm text-gray-600">
              {{ userLicense?.key }}
            </div>
          </div>
        </div>

        <div>
          <div class="flex">
            <div class="colon text-gray-600">
              {{ $t("app.start_date") }}
            </div>

            <div>
              {{ startDate }}
            </div>
          </div>

          <div class="flex">
            <div class="colon text-gray-600">
              {{ $t("app.end_date") }}
            </div>

            <div>
              {{ expiryDate }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useOptions from "@/composables/useOptions";
// Components
import LicenseLogo from "@/components/LicenseLogo";
import VSelect from "@/components/inputs/VSelect";
import VCard from "@/components/VCard";

export default {
  components: {
    LicenseLogo,
    VSelect,
    VCard
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    userLicense: {
      type: Object,
      required: true
    }
  },
  emits: ["update:license"],
  setup(props, { emit }) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getLicenses } = useOptions();
    const { formatDate } = useDisplay();

    // Data
    const license = ref("");
    const licenses = ref([]);

    // Computed
    const options = computed(() => {
      if (!licenses.value || licenses.value.length === 0) {
        return [];
      }

      return licenses.value.map(license => ({
        ...license,
        disabled: license.id === props.userLicense?.id
      }));
    });

    const startDate = computed(() => {
      const value = props.userLicense?.start_date ?? "";
      return formatDate({ date: value });
    });

    const expiryDate = computed(() => {
      const value = props.userLicense?.expiry_date ?? "";
      return formatDate({ date: value, fallback: t("app.perpetual_license") });
    });

    // Methods
    const onUpdate = licenseId => {
      emit("update:license", licenseId);
      license.value = "";
    };

    // Lifecycle Hooks
    onMounted(async () => {
      licenses.value = await getLicenses();
    });

    return {
      license,
      options,
      onUpdate,
      startDate,
      expiryDate,
      // useDisplay
      formatDate
    };
  }
};
</script>
