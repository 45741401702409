<template>
  <div
    class="w-16 h-16 relative inline-block align-middle rounded-full bg-theme-1"
  >
    <div
      class="left-1/2 top-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 text-white"
    >
      <i class="fal fa-certificate fa-3x"></i>
    </div>
  </div>
</template>
